import Vue from 'vue'
import VueRouter from 'vue-router'
import ShiShenPc from '../views/ShiShenPc'

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'ShiShenPc',
        component: ShiShenPc
    }

]

const router = new VueRouter({
    routes
})

export default router
