import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
// 引入字体库
import './assets/font/font.css'
// 引入移动组件库
import Vant, { Locale } from "vant"
import 'vant/lib/index.css'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US'
import { register } from 'swiper/element/bundle';
import { NoticeBar } from 'vant';
Vue.use(NoticeBar);
Vue.use(register)
Locale.use('en-US', enUS)
Vue.use(Vant)
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
