import request from '@/api/request'

export function getShiShenBaZi(data) {
    return request(
        {
            method: 'post',
            url: '/baZi/shiShen',
            data
        }
    )
}
