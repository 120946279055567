<template>
  <div class="page">
    <div class="box">
      <div class="top_img">
        <img
            src="http://oss-cnpc.oss-cn-hangzhou.aliyuncs.com/files/2023-03-27/e2d57263-2a51-46b8-b0d1-9ecbf2bce5dd.png?Expires=7987082175&OSSAccessKeyId=LTAI4G6y3dzrE3KutXEuqtnn&Signature=d4oibToC6JK9Krk1Y4YEsWk6dds%3D"
            alt="">
      </div>
      <!-- select -->
      <div class="select_box">
        <el-row :gutter="30">
          <el-col :lg="8" :sm="24">
            <div class="select_item birthday_icon">
              <el-date-picker v-model="selectForm.birthdayTime" type="datetime"
                              placeholder="your date of birth" default-time="" :clearable="false" :editable="false">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :lg="8" :sm="24">
            <div class="select_item region_icon">
              <el-select v-model="selectForm.region" value-key="id" filterable placeholder="your region">
                <el-option v-for="item in addressList" :key="item.id" :label="item.cityStr" :value="item">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="8" :sm="24">
            <div class="select_item gender_icon">
              <el-select v-model="selectForm.gender" placeholder="your gender">
                <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <!--十神-->
      <div class="shishen_box">
        <div class="tian_gan">
          <div class="outward">outward</div>
          <div class="tian_shishen_box">
            <el-row :gutter="5">
              <el-col :span="6" v-for="(shiShen, index) in shiShenList.slice(0,4)" :key="index">
                <div :class="shiShen.shiShenRollover ? 'shishen_item shishen_item_rollover' : 'shishen_item' ">
                  <div class="header header_rollover"
                       :style="{'backgroundImage': 'url(' + shiShen.shiShenImg + ')'}">
                    <div class="text_box"
                         :style="{'backgroundImage': 'url(' + shiShen.ganZhiWordImg + ')'}">
                      <div class="desc text_zoom">
                        <input class="title" type="text" :value="shiShen.shiShenEng" disabled>
                        <input class="content" type="text" :value="shiShen.shiShenEngContent"
                               disabled>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="footer">
                  <div class="title">{{ footerList[index] }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!--         地支-->
        <div class="tian_gan di_zhi">
          <div class="inward">inward</div>
          <div class="tian_shishen_box">
            <el-row :gutter="5">
              <el-row :gutter="5">
                <el-col :span="6" v-for="(shiShen, index) in shiShenList.slice(4,8)" :key="index">
                  <div :class="shiShen.shiShenRollover ? 'shishen_item shishen_item_rollover' : 'shishen_item' ">
                    <div class="header header_rollover"
                         :style="{'backgroundImage': 'url(' + shiShen.shiShenImg + ')'}">
                      <div class="text_box"
                           :style="{'backgroundImage': 'url(' + shiShen.ganZhiWordImg + ')'}">
                        <div class="desc text_zoom">
                          <input class="title" type="text" :value="shiShen.shiShenEng" disabled>
                          <input class="content" type="text" :value="shiShen.shiShenEngContent"
                                 disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="footer">
                    <div class="title">{{ footerList[index + 4]}}</div>
                  </div>
                </el-col>
              </el-row>
            </el-row>
          </div>
        </div>
      </div>
      <!--大运-->
      <div class="dayun_box">
        <div class="fortune">fortune</div>
        <div class="liu_nian_box">
          <div class="right_top_icon">
            <div class="icon_border">
              <div class="icon_border_shadow">
              </div>
            </div>
            <div class="icon_shape"></div>
          </div>
          <div class="left_bottom_icon">
            <div class="icon_border">
              <div class="icon_border_shadow">
              </div>
            </div>
            <div class="icon_shape"></div>
          </div>
          <div class="swiper_box">
            <swiper-container :breakpoints.prop="swiper.breakpoints" :pagination.prop="swiper.pagination"
                              :style="swiper.style" :navigation.prop="swiper.navigation">
              <swiper-slide class="swiper_item" v-for="(daYun, index) in shiShenInfo.shiShenDaYuns" :key="index"
                            :virtualIndex="index">
                <div class="dayun">
                  <el-row>
                    <el-col :span="12">
                      <img class="gan" :src="daYun.daYunAvatars[0]" alt="">
                    </el-col>
                    <el-col :span="12">
                      <img class="zhi" :src="daYun.daYunAvatars[1]" alt="">
                    </el-col>
                  </el-row>
                </div>
                <div class="age">
                  AGE: {{ daYun.age }}
                </div>
                <div class="year">
                  YEARS: {{ daYun.year }}
                </div>
                <div class="liu_nian" v-for="(liuNian, index) in daYun.liuNianAvatars" :key="index">
                  <el-row>
                    <el-col :span="12">
                      <img class="gan" :src="liuNian[0]" alt="">
                    </el-col>
                    <el-col :span="12">
                      <img class="zhi" :src="liuNian[1]" alt="">
                    </el-col>
                  </el-row>
                </div>
              </swiper-slide>
            </swiper-container>
          </div>
        </div>
      </div>
    </div>
    <!--底部 -->
    <div class="footer">
      <div class="left_line">
      </div>
      <div class="icon">
        <img src="@/assets/image/icon/bilibili.png" alt="">
        <img src="@/assets/image/icon/youtube.png" alt="">
        <img src="@/assets/image/icon/twitter.png" alt="">
      </div>
      <div class="right_line">
      </div>
    </div>
  </div>
</template>

<script>
import positions from '@/assets/js/position'
import {getShiShenBaZi} from '@/api'
import {nativeJs, DateTimeFormatter} from '@js-joda/core'

export default {
  name: 'ShiShenPc',
  components: {},
  data() {
    return {
      selectForm: {
        region: {
          cityEn: "",
          cityStr: "",
          countryEn: "",
          id: "",
          latitude: "",
          longitude: "",
        },
        gender: "男",
        birthdayTime: new Date()
      },

      addressList: [{
        id: "",
        cityEn: "",
        countryEn: "",
        longitude: "",
        latitude: "",
        cityStr: ""
      }],
      genderList: [
        {
          label: 'men',
          value: '男'
        },
        {
          label: 'women',
          value: '女'
        }
      ],
      footerList: ['ancestry', 'cooperation', 'yourself', 'manner', 'ancestry', 'foremost', 'soulmate', 'descendant'],
      shiShenInfo: {
        yearGanShiShen: {},
        monthGanShiShen: {},
        dayGanShiShen: {},
        timeGanShiShen: {},
        yearZhiShiShen: {},
        monthZhiShiShen: {},
        dayZhiShiShen: {},
        timeZhiShiShen: {},
        shiShenDaYuns: [...new Array(6).fill(
            {
              daYunAvatars: ["", ""],
              age: "",
              year: "",
              liuNianAvatars: [
                ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""],
              ]
            }
        )]
      },
      shiShenList: [...new Array(8).fill(
          {
            shiShenImg: "",
            shiShenEng: "",
            shiShenEngContent: "",
            ganZhiWordImg: "",
            ganZhiWord: "",
            shiShenRollover: false
          })],
      swiper: {
        breakpoints: {
          '0': {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          '768': {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          '992': {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          '1200': {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          '1920': {
            slidesPerView: 6,
            spaceBetween: 10,
          },
        },
        pagination: {
          clickable: true
        },
        style: {
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        },
        navigation: true
      }
    }
  },
  watch: {
    selectForm: {
      handler() {
        this.getShiShenBaZi();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  mounted() {
    this.getPositions();
  },
  methods: {
    // 城市处理
    getPositions() {
      let id = 122;
      positions.forEach(x => {
        const city = {
          id: id++,
          cityEn: x.cityEn,
          countryEn: x.countryEn,
          longitude: x.longitude,
          latitude: x.latitude,
          cityStr: x.countryEn + "-" + x.cityEn
        }
        this.addressList.push(city);
      })
    },

    // 请求数据
    async getShiShenBaZi() {
      if (this.selectForm.gender && this.selectForm.birthdayTime) {
        const shiShenQuery = {
          birthdayTime: nativeJs(this.selectForm.birthdayTime).format(DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss")),
          gender: this.selectForm.gender,
          longitude: this.selectForm.region.longitude,
          latitude: this.selectForm.region.latitude
        }
        const res = (await getShiShenBaZi(shiShenQuery)).data.data;
        //处理数据 方便使用
        const shiShenList = [];
        shiShenList.push(res.yearGanShiShen);
        shiShenList.push(res.monthGanShiShen);
        shiShenList.push(res.dayGanShiShen);
        shiShenList.push(res.timeGanShiShen);
        shiShenList.push(res.yearZhiShiShen);
        shiShenList.push(res.monthZhiShiShen);
        shiShenList.push(res.dayZhiShiShen);
        shiShenList.push(res.timeZhiShiShen);
        //判断动画执行
        for (let i = 0; i < shiShenList.length; i++) {
          if (this.shiShenList[i].shiShenEng === shiShenList[i].shiShenEng) {
            shiShenList[i].shiShenRollover = true;
          } else {
            this.shiShenList[i].shiShenRollover = false;
            shiShenList[i].shiShenRollover = true;
          }
        }
        setTimeout(() => {
          this.shiShenInfo = res;
          this.shiShenList = shiShenList;
        }, 400)
      }
    }
  }

}
</script>
<style lang="scss">
/*输入框背景颜色边框字体*/
.el-input__inner {
  border-image: linear-gradient(136deg, rgba(176, 56, 194, 1), rgba(70, 130, 217, 1)) 1 1 !important;
  background: rgba(255, 255, 255, 0.1) !important;
  color: #ffffff !important;
  box-sizing: border-box;
}

/*弹出框样式*/
.el-picker-panel {
  background: #312F46 !important;
  color: #fff !important;
  border-image: linear-gradient(136deg, rgba(177, 56, 194, 1), rgba(60, 104, 176, 1)) 1 1 !important;
  box-sizing: border-box;
}

/*弹出框底部样式*/
.el-picker-panel__footer {
  background: #312F46 !important;
  color: #fff !important;
  border-image: linear-gradient(136deg, rgba(177, 56, 194, 1), rgba(60, 104, 176, 1)) 1 1 !important;
  box-sizing: border-box;
}

.el-picker-panel__icon-btn {
  color: #ffffff !important;
}

/*下拉框颜色*/
.el-select-dropdown {
  background-color: #312f46 !important;
  border-image: linear-gradient(136deg, rgba(177, 56, 194, 1), rgba(60, 104, 176, 1)) 1 1 !important;
  box-sizing: border-box;
}

/*选中颜色*/
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background-color: #464459 !important;
}
</style>
<style lang="scss" scoped>
/*页面*/
.page {
  background: url("@/assets/image/background.png") no-repeat fixed;
  background-size: 100% 100%;
  min-width: 375px;
  min-height: 100vh;
  max-width: 2560px;
  margin: 0 auto;
  overflow: hidden;
}

.text_zoom {

}

/*响应式盒子*/
@media (min-width: 1920px) {
  .box {
    width: 36%;
  }
  .text_zoom {
    transform: scale(0.8);
    transform-origin: 50% 0;
  }
}

@media screen and (max-width: 1200px) {
  .box {
    width: 60%;
  }
  .text_zoom {
    transform: scale(0.8);
    transform-origin: 50% 0;
  }
}

@media screen and (max-width: 992px) {
  .box {
    width: 80%;
  }
  .text_zoom {
    transform: scale(0.8);
    transform-origin: 50% 0;
  }
}

@media screen and (max-width: 768px) {
  .box {
    width: 100%;
  }
  .text_zoom {
    transform: scale(0.67);
    transform-origin: 50% 0;
  }
}

/*最外层盒子*/
.box {
  margin: 0 auto;

  .top_img {
    margin: 0 auto;
    width: 100%;

    img {
      width: 88%;
      margin-left: 6%;
    }
  }

  .select_box {
    width: 95%;
    margin: 10px auto;
    height: auto;

    .select_item {
      width: 100%;
      height: auto;
    }

    ::v-deep .el-input, .el-select {
      width: 100%;
    }

    ::v-deep .el-input__inner {
      height: 50px;
      font-size: 16px;
      padding-left: 18%;
    }

    //生日选择框左边图标
    .birthday_icon ::v-deep .el-input__prefix .el-icon-time::before {
      content: "";
    }

    .birthday_icon ::v-deep .el-input--suffix {
      background: url("@/assets/image/icon/date.png") no-repeat 5%;
      background-size: 25px 25px;
      height: 100%;
      width: 100%;
    }

    //生日选择框右边图标
    .birthday_icon ::v-deep .el-input__suffix {
      content: "";
      height: 100%;
      width: 100%;
      background: url("@/assets/image/icon/right.png") no-repeat 95%;
    }

    //地区左边图标
    .region_icon ::v-deep .el-input--suffix {
      background: url("@/assets/image/icon/region.png") no-repeat 5%;
      background-size: 25px 25px;
    }

    //地区右边图标
    .region_icon ::v-deep .el-icon-arrow-up {
      transform: rotateZ(0);
    }

    .region_icon ::v-deep .el-icon-arrow-up::before {
      content: "";
    }

    .region_icon ::v-deep .el-input__suffix {
      background: url("@/assets/image/icon/right.png") no-repeat 95%;
      width: 100%;
      height: 100%;
    }

    //左边图标
    .gender_icon ::v-deep .el-input--suffix {
      background: url("@/assets/image/icon/gender.png") no-repeat 5%; //引入icon
      background-size: 25px 25px;
    }

    //右边图标
    .gender_icon ::v-deep .el-icon-arrow-up {
      transform: rotateZ(0);
    }

    .gender_icon ::v-deep .el-icon-arrow-up::before {
      content: "";
    }

    .gender_icon ::v-deep .el-input__suffix {
      background: url("@/assets/image/icon/right.png") no-repeat 95%;
      width: 100%;
      height: 100%;
    }


  }

  //十神盒子
  .shishen_box {
    width: 98%;
    margin: 10px auto;
    height: auto;

    .di_zhi {
      margin-top: 10px;
    }

    .tian_gan {
      width: 100%;

      .outward {
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background-color: #27243e;
        border-radius: 5px;
        opacity: .7;
        color: #4dd95f;
        font-size: 24px;
        font-family: 'lake', serif;
      }

      .inward {
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background-color: #27243e;
        border-radius: 5px;
        opacity: .7;
        color: #3279EA;
        font-size: 24px;
        font-family: 'lake', serif;
      }

      .tian_shishen_box {
        margin-top: 20px;

        .shishen_item {
          width: 100%;
          height: auto;
          transition: 0.8s ease-in-out;
          transform-style: preserve-3d;

          .header {
            width: 100%;
            height: 0;
            background-repeat: no-repeat;
            padding-top: 174.3%;
            box-sizing: border-box;
            object-fit: fill;
            background-size: 100% 100%;
            backface-visibility: hidden;

            .text_box {
              width: 100%;
              height: 0;
              margin-top: -60%;
              padding-top: 30%;
              background-repeat: no-repeat;
              background-position-x: 5%;
              background-position-y: 15%;
              background-size: 12%;

              .desc {
                width: 100%;
                height: auto;
                margin-top: -30%;

                .title {
                  color: #ffffff;
                  background-color: transparent;
                  text-align: center;
                  border: 0;
                  width: 98%;
                  font-size: clamp(0.7rem, 1.5vw, 1rem);
                  font-family: 'lake', serif;
                  white-space: normal;
                }

                .content {
                  background-color: transparent;
                  border: 0;
                  text-align: center;
                  width: 98%;
                  color: #ffffff;
                  font-size: clamp(0.5rem, 1vw, 0.7rem);
                  font-family: 'lake', serif;
                  white-space: nowrap;
                  overflow: scroll;
                }
              }
            }
          }

          .header_rollover {
            transform: rotateY(180deg);
          }
        }

        .shishen_item_rollover {
          transform: rotateY(180deg);
        }

        .footer {
          box-sizing: border-box;
          width: 100%;
          height: 0;
          padding-top: 20%;
          margin-top: 15px;
          border: solid 1px;
          border-image: linear-gradient(136deg, rgba(176, 56, 194, 1), rgba(70, 130, 217, 1)) 1 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .title {
            margin-top: -20%;
            font-size: clamp(0.6rem, 1.4vw, 20px);
            font-family: 'lake', serif;
            background-image: linear-gradient(136deg, rgba(176, 56, 194, 1), rgba(70, 130, 217, 1));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

    }
  }

  //大运盒子
  .dayun_box {
    width: 98%;
    margin: 20px auto;
    height: auto;

    .fortune {
      width: 100%;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background-color: #27243e;
      border-radius: 5px;
      opacity: .7;
      color: #4dd95f;
      box-sizing: border-box;
      font-size: 24px;
      font-family: 'lake', serif;
    }

    .liu_nian_box {
      width: 100%;
      margin-top: 10px;
      height: auto;
      position: relative;

      .right_top_icon {
        position: absolute;
        left: 95%;
        top: -2.5%;
        width: 10%;
        height: 0;
        padding-top: 10%;
        z-index: 2;

        .icon_border {
          width: 100%;
          padding-top: 100%;
          margin-top: -100%;
          filter: drop-shadow(-1px 1px 2px white) blur(1px);

          .icon_border_shadow {
            width: 100%;
            padding-top: 100%;
            margin-top: -100%;
            background-color: rgba(255, 255, 255, 0.5);
            clip-path: polygon(0% 0%, 0% 25%, 75% 100%,
                75.4% 99%, 1% 24.4%, 1% 1%, 99% 1%, 99% 99%, 75.4% 99%, 75% 100%,
                100% 100%, 100% 0%, 0% 0%);
          }
        }

        .icon_shape {
          width: 100%;
          padding-top: 100%;
          margin-top: -100%;
          backdrop-filter: blur(5px);
          clip-path: polygon(75% 100%, 0% 25%, 0% 0%, 100% 0%, 100% 100%);
        }
      }

      .left_bottom_icon {
        position: absolute;
        left: -5%;
        bottom: -2%;
        width: 10%;
        height: 0;
        padding-top: 10%;
        z-index: 2;
        rotate: 180deg;

        .icon_border {
          width: 100%;
          padding-top: 100%;
          margin-top: -100%;
          filter: drop-shadow(-1px 1px 2px white) blur(1px);

          .icon_border_shadow {
            width: 100%;
            padding-top: 100%;
            margin-top: -100%;
            background-color: rgba(255, 255, 255, 0.5);
            clip-path: polygon(0% 0%, 0% 25%, 75% 100%,
                75.4% 99%, 1% 24.4%, 1% 1%, 99% 1%, 99% 99%, 75.4% 99%, 75% 100%,
                100% 100%, 100% 0%, 0% 0%);
          }
        }

        .icon_shape {
          width: 100%;
          padding-top: 100%;
          margin-top: -100%;
          backdrop-filter: blur(5px);
          clip-path: polygon(75% 100%, 0% 25%, 0% 0%, 100% 0%, 100% 100%);
        }
      }

      .swiper_box {
        width: 100%;
      }

      .swiper_item {
        background-image: url("@/assets/image/dayun.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-bottom: 20px;

        .dayun {
          margin-top: 20px;

          .gan {
            width: 80%;
            float: right;
            margin-right: 5%;
          }

          .zhi {
            width: 80%;
            float: left;
            margin-left: 5%;
          }
        }

        .age {
          margin-top: 10px;
          text-align: center;
          font-size: clamp(0.5rem, 1.5vw, 20px);
          font-family: 'Alfa Slab One', serif;
          color: #CDFF00;
        }

        .year {
          margin-top: 5px;
          text-align: center;
          font-size: clamp(0.5rem, 1vw, 20px);
          background: linear-gradient(91deg, #FD9E2E 0%, #EE6337 100%);
          font-family: 'Alfa Slab One', serif;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .liu_nian {
          margin-top: 10px;

          .gan {
            width: 75%;
            float: right;
            margin-right: 5%;
          }

          .zhi {
            width: 75%;
            float: left;
            margin-left: 5%;;
          }
        }
      }
    }
  }
}

//底部
.footer {
  width: 100%;
  height: 60px;
  background-color: #1C144C;
  display: flex;
  align-items: center;

  .left_line {
    margin-left: 25%;
    height: 2px;
    width: 20%;
    background: rgba(255, 255, 255, 0.2);
  }

  .icon {
    float: left;
    width: 10%;

    img {
      width: 10%;
      margin-left: 20%;
    }
  }

  .right_line {
    float: left;
    height: 2px;
    width: 20%;
    background: rgba(255, 255, 255, 0.2);
  }
}
</style>
